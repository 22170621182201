@import "../../../assets/styles/variables";

.verifyCpnContainer {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.searchContainer {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 10px;
  gap: 5px;
  & > div {
    height: 30px;
    width: 100%;
  }
  & > div > div {
    height: 100%;
  }
}
.searchIcon {
  display: flex;
}

.verifyButton {
  height: 30px;
  margin-left: -6px;
  height: 29px;
  background-color: $coupon-button-color;
  color: $white-text-color;
  text-transform: capitalize;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid $coupon-button-color;
  &:hover {
    background-color: $coupon-button-color;
  }
}
