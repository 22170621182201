@import "../../../assets/styles/variables";
.tabsContainer {
  background-color: #f3f3f3;
}
.tabs {
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.tabButton {
  min-height: auto;
  height: 32px;
  background-color: $secondary-background-color;
  color: $coupon-button-color;
  border: 1px solid $coupon-button-color;
  flex-grow: 1;
  font-weight: 400;
  text-transform: capitalize;
  &:hover {
    background: transparent;
    border: 1px solid $coupon-button-color;
  }
}
.tabButtonActive {
  background-color: $coupon-button-color;
  color: $white-text-color;
  border: 1px solid $coupon-button-color;
  &:hover {
    background-color: $coupon-button-color;
  }
}
.couponTypeLabel {
  text-transform: capitalize;
}
