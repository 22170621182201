@import "../../../assets/styles/variables";

.fixedBox {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 25px;
  height: 20px;
  margin-left: auto;
  margin-right: 10px;
}
.fixed {
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid $coupon-button-color;
  border-radius: 50%;
  padding: 10px;
  margin: 4px;
  font-size: $font-size-xl;
  @media (min-width: $max-width-tablet) {
    cursor: pointer;
  }
}
.fixedChecked {
  background-color: $coupon-button-color;
  color: $white-text-color;
  border: 1px solid $coupon-button-color;
}
.oddContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 60px;
  height: 23px;
}
