@import "../../../assets/styles/variables";

.container {
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.spinner {
  margin-right: 10px;
}
