@import "../../../assets/styles/variables";

.event {
  display: flex;
  flex-flow: column;
  border-radius: 0px 4px 0px 0px;
  height: 32px;
  background-image: linear-gradient(
    180deg,
    $coupon-event-background-color 0%,
    $coupon-event-background-color 100%
  );
  width: 100%;
  justify-content: center;
}
.eventLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.infoIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin-right: 6px;
  margin-left: 6px;
  color: $white-text-color;
  @media (min-width: $max-width-tablet) {
    cursor: pointer;
  }
}

.eventLabel {
  color: $white-text-color;
  font-weight: 600;
  font-size: $font-size-s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
.fullWidthLabel {
  width: 100%;
  text-align: center;
}
.eventLabelDivider {
  color: $white-text-color;
  width: 20%;
  text-align: center;
}
.trashIcon {
  margin-right: 15px;
  color: $white-text-color;
  @media (min-width: $max-width-tablet) {
    cursor: pointer;
  }
}

// event info
.eventInfoContainer {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin-bottom: 1px;
  padding: 8px;
}

.eventShortContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eventInfo {
  display: flex;
  width: 80%;
}
.eventLabelInfo {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.eventLabelDividerInfo {
  width: 4%;
  text-align: center;
}
.sportLabel {
  margin-right: 4px;
}
.couponInfo {
  display: flex;
  align-items: center;
}
.labelInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.categoryTime {
  display: flex;
  justify-content: space-between;
}
.categoryLabel {
  margin-right: 4px;
}
.categoryShort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tournamentLabel {
  margin-right: 4px;
}
.icon {
  width: 15px;
}
