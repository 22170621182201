@import "../../../assets/styles/variables";
.notAuthContainer {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.message {
  font-size: $font-size-xxl;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon {
  font-size: 24px;
}
