@import "../../../assets/styles/variables";

.textfield,
.textfieldDefault {
  width: 100%;
}

.menuItem {
  padding: 15px;
}
