@import "../../../assets/styles/variables";
.oddContainer {
  width: 100%;
  position: relative;
  height: 100%;
}
.oddContent {
  border-radius: 4px;
  display: flex;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: $odd-background-color;
  color: #343a40;
  position: relative;
  font-weight: 600;
  font-size: $font-size-xl;
  padding: 4px;
  cursor: pointer;
  @media (min-width: $max-width-small-desktop) {
    &:hover {
      background-color: #f0566f;
      color: $white-text-color;
      outline: 1px solid #f0566f;
    }
  }
}

.couponOddContent {
  border-radius: 4px;
  display: flex;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: $odd-background-color;
  svg {
    font-size: $font-size-xxl;
  }
}
.clickedOdd {
  display: flex;
  color: $white-text-color;
  font-size: $font-size-xl;
  line-height: 2.5;
  justify-content: center;
  align-items: center;
  background: $coupon-button-color;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 3px 3px #00000029);
  & > div {
    line-height: 2.5;
  }
}
.upIndicator {
  color: $odd-color;
  position: absolute;
  height: 50%;
  display: flex;
  align-items: center;
  right: 1%;
  top: 0;
}
.downIndicator {
  color: $coupon-button-color;
  position: absolute;
  height: 90%;
  display: flex;
  align-items: flex-end;
  right: 2%;
  top: 8px;
}
.up {
  border: 1px solid $odd-color;
  color: $odd-color;
  font-size: $font-size-xl;
  font-weight: 600;
}
.down {
  border: 1px solid $coupon-button-color;
  color: $coupon-button-color;
  font-size: $font-size-xl;
  font-weight: 600;
}
