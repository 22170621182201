@import "../../../assets/styles/variables";

.container {
  margin-bottom: 5px;
  padding: 2px;
}
.textBox {
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.btncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 10px;
}

.button {
  padding: 7px;
}

.button > span > div {
  font-size: $font-size-s !important;
}

.buttonLabel {
  font-size: $font-size-xxs;
}
