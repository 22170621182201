@import "../../../assets/styles/variables";

.sharedViewcontainer {
  display: flex;
  flex-direction: column;
}
.amountsContainer {
  background: #0000001a;
  padding: 6px;
  margin: 5px 10px;
  border-radius: 3px;
}
.amountInputContainer {
  display: grid;
  grid-gap: 3px;
  grid-template-columns: 25% 25% 20% 30%;
  align-items: center;
  margin: 5px;
}
.amountText,
.selectionText {
  font-size: $font-size-s;
  font-weight: 600;
  opacity: 0.8;
}
.amountInput {
  width: 100%;
  & > div {
    height: 25px;
  }
  @media (min-width: $max-width-mobile) {
    margin-left: auto;
    margin-right: 15px;
  }
}
.clearBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
}
.clearButton {
  height: 31px;
  text-transform: capitalize;
  background-color: $coupon-button-color;
  border: 1px solid $coupon-button-color;
  border-radius: 4px;
  color: $white-text-color;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    border: 1px solid $coupon-button-color;
    color: $coupon-button-color;
  }
  @media (max-width: $max-width-small-desktop) {
    min-width: 20px;
  }

  @media (min-width: $max-width-tablet) {
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: $max-width-tablet) {
    width: 150px;
  }
}
.amountButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  padding: 5px 0;
}
.amountButton {
  width: 100%;
  background-color: $coupon-amount-button-color;
  border: 1px solid $border-color;
  color: $coupon-button-color;
  min-width: 40px;
  height: 30px;
  font-weight: bolder;
  padding: 2px;
  border-radius: 4px;
  font-size: $font-size-l;
  cursor: pointer;
  &:hover {
    background-color: $coupon-button-color;
    color: $coupon-amount-button-color;
    border: 1px solid transparent;
  }
}

.transferToBox {
  display: flex;
  gap: 5px;
  height: 30px;
  align-items: center;
  flex-direction: row;
}
.transferToLabel {
  margin-left: 5px;
}
.childrenSelectBox {
  margin: 10px 5px;
}
.infoContainer {
  display: flex;
  flex-flow: row;
  border: 1px solid $border-color;
  margin-top: 10px;
  margin-bottom: 5px;
}
.infoContainerColumn {
  width: 100%;
  display: flex;
  flex-flow: column;
  border-right: 1px solid $border-color;

  &:last-child {
    border-bottom: 0;
  }
}
.infoContainerColumnItem {
  width: 100%;
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid $border-color;
  height: 23px;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;
  & > p {
    line-height: 1;
  }
  &:last-child {
    border-right: 0;
  }
}
.infoLabel {
  font-size: $font-size-l;
  @media (max-width: $max-width-small-desktop) {
    font-size: $font-size-xxs;
  }
}
.numberLabel {
  font-weight: bold;
  font-size: $font-size-l;

  @media (max-width: $max-width-small-desktop) {
    font-size: $font-size-xxs;
  }
}
.totalOddLabel {
  font-size: $font-size-l;
  font-weight: bold;
  @media (max-width: $max-width-small-desktop) {
    font-size: $font-size-xxs;
  }
}

.radiobuttonsContainer {
  padding: 10px 10px 5px 10px;
  margin-top: 10px;
  @media (max-width: $max-width-small-desktop) {
    & > div {
      padding-top: 10px;
      gap: 15px;
    }
  }
}
.radiobuttonLabelMin {
  @media (max-width: $max-width-desktop) {
    margin-bottom: 10px;
  }
}

//////////////////////////////////////////////////
.playedAmount {
  background-color: #00000026;
  color: $text-color;
  font-weight: 600;
  line-height: 3;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  height: 59px;
  font-size: 22px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 3px 3.5px #00000029);
}
.btncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
  gap: 10px;

  @media (max-width: $max-width-small-desktop) {
    gap: 5px;
  }
}
.cancelBtn {
  background-color: $coupon-cancel-button-color;
  border: 1px solid $coupon-cancel-button-color;
  color: $white-text-color;
  text-transform: capitalize;
  text-align: center;
  width: 100%;
  height: 36px;
  font-size: $font-size-xl;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #5c636a;
  }
  @media (min-width: $max-width-tablet) {
    &:hover {
      opacity: 1;
    }
  }
}
.buttonDisabled {
  background-color: #252525ec;
  width: 100%;
  height: 36px;
  pointer-events: none;
  user-select: none;
  span {
    color: #b8aeae;
  }
}
.bookBtn {
  background-color: $coupon-book-button-color;
  border: 1px solid $coupon-book-button-color;
  border-radius: 5px;
  color: $white-text-color;
  font-size: $font-size-xl;
  width: 100%;
  height: 36px;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    background-color: #26869e;
  }
  @media (min-width: $max-width-tablet) {
    &:hover {
      opacity: 1;
    }
  }
}
.proceedBtn {
  background-color: $coupon-proceed-button-color;
  border: 1px solid $coupon-proceed-button-color;
  border-radius: 5px;
  color: $white-text-color;
  font-size: $font-size-xl;
  width: 100%;
  height: 36px;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    background-color: #157347;
  }
  @media (min-width: $max-width-tablet) {
    &:hover {
      opacity: 1;
    }
  }
}
.dialog {
  .dialogTitle {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
    text-transform: capitalize;

    & > div > button {
      & > div {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .dialogContent {
    padding-top: 4px;
  }
  & > div > div {
    box-shadow: 0px 0px 5px #8b8b8b;
  }
  @media (max-width: $max-width-small-mobile) {
    & > div > div {
      margin-left: 0;
      margin-right: 0;
      width: calc(100% - 15px);
    }
  }
}
.importValueMessage {
  margin: 5px;
  max-width: 100%;
  position: relative;
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.importValueMessageUser {
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  width: 50%;
  position: relative;
  left: 25%;
  margin-bottom: 2%;
}

.emptyImportMessage {
  text-align: center;
}
