a {
  text-decoration: none;
  color: #1f4254;
}
* {
  scrollbar-width: 3px;
  scrollbar-color: #1f42541a;
}

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #e6e7e8;
}
::-webkit-scrollbar-thumb {
  background: #9d9d9d;
}
