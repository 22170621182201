@import "../../../assets/styles/variables";

.dialog {
  width: 100%;
  background-color: transparent;
  & > div > div {
    box-shadow: 0px 0px 5px #8b8b8b;
    margin: 0px !important;
  }
}
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.titleLabel {
  font-size: $font-size-xxxl;
  font-weight: 900 !important;
}
.titleLabelLogin {
  display: flex;
  align-items: center;
  font-size: $font-size-xxxl;
  font-weight: 900 !important;
  text-transform: capitalize;
}
.titleLabelUserName {
  text-transform: none;
  font-size: $font-size-xxxl;
  font-weight: 900;
}
.dialogTitle {
  display: grid;
  // justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
}
.contentContainer {
  background-color: transparent;
  overflow-y: scroll;
}

.dialogContainer {
  position: relative;
  min-width: 300px;
}

.closeIcon {
  text-align: end;
  justify-self: flex-end !important;
  font-size: $font-size-xxxl;
  margin-bottom: 10px;
  @media (min-width: $max-width-tablet) {
    cursor: pointer;
  }
}

.searchCasinoGameContainer {
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.idleTimerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  gap: 5px;
}

.noPadding {
  padding: 0px 24px;
  border-radius: 20px;
}
