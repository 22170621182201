@import "../../../assets/styles/variables";

.gridContainer {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: rgb(0 0 0 / 10%);
  margin: 4px;
}
.gridContainerSystem {
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-top: 4px;
}

.gridRowIntegral {
  overflow: hidden;
  margin: 5px;
}
.gridRowContainer {
  overflow: hidden;
}
