@import "../../../assets/styles/variables";

.oddType {
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 5px;
}

.label {
  font-size: $font-size-xl;
  font-weight: bold;
  padding-bottom: 5px;
  color: var(--text-color);
}

.select {
  width: 100%;
  height: 40px;
  font-size: $font-size-l;
  font-weight: bold;
  color: var(--text-color);
}

.option {
  color: var(--text-color);
  font-size: $font-size-l;
  border-bottom: solid 1px #bbb;
}
