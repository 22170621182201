@import "../../../assets/styles/variables";

.gridHeader {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 20% 20% 25% 35%;
}

.gridHeaderItem {
  font-size: $font-size-m;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
