@import "../../../assets/styles/variables";

.gridRow {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 20% 20% 25% 35%;
  background-color: #00000012;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2px;
  height: 38px;
  width: 100%;
}
.containerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}

.gridRowItem {
  text-align: center;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}
.amountInput {
  width: 92%;
  & > div {
    height: 25px;
  }
}
.gridRowItemContent {
  display: flex;
  flex-direction: column;
}
.gridRowItemContentItem {
  text-align: center;
}

.checkboxLight {
  color: #b8aeae;
}
