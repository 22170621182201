@import "../../../assets/styles/variables";

.checkBoxContainer {
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: relative;
  border-radius: 4px;
  outline: solid 1px $text-color;
  @media (min-width: $max-width-tablet) {
    cursor: pointer;
  }
  .checkedIcon {
    margin-top: 25%;
    color: $text-color;
  }
}

.smallCheckbox {
  height: 14px;
  width: 14px;
  font-size: $font-size-xxxs;
}
.checkedBg {
  background-color: $white-text-color;
  color: $text-color;
}
.checkbox {
  font-size: $font-size-m;
  font-weight: 600;
}
