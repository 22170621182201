@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hourglass {
  font-size: 40px;
  animation-name: spin;
  animation: spin 2.5s infinite;
}
