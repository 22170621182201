@import "../../../assets/styles/variables";

.accordion {
  font-size: $font-size-l;
  margin: 2px 0;
}
.accordionSummary {
  height: 35px;
  min-height: 35px !important;
}
.accordionDetails {
  min-height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.quickBetFields {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 18px;
}
.eventFieldContainer {
  width: 30%;
  flex-direction: column;

  @media (max-width: $max-width-small-desktop) {
    width: 45%;
  }
}
.eventField {
  div {
    height: 40px;
    border-radius: 10px;
  }
  label {
    font-size: $font-size-l;
    top: -10%;
  }
  input {
    font-size: $font-size-m;
  }
}
.eventContainerLabel {
  display: flex;
  flex-direction: row;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 6px;
  width: 90%;
  bottom: 2px;
}
.quickBetCodes {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  margin: 2px;
  @media (max-width: $max-width-small-desktop) {
    & > div {
      font-size: $font-size-xxl;
    }
  }
}
.quickBetCodesLabel {
  font-size: $font-size-l;

  @media (max-width: $max-width-small-desktop) {
    font-size: $font-size-s;
  }
}
.eventLabel {
  font-size: $font-size-m;
  line-height: 2.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.eventContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 6px;
  width: 90%;
  bottom: 2px;
}

.eventLabelCenter {
  font-size: $font-size-m;
  line-height: 2.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10%;
}
.textFieldOption {
  cursor: pointer;
}
.autocompleteContainer {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  height: 40px;
  @media (max-width: $max-width-small-desktop) {
    width: 60%;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 10px;
    label {
      font-size: $font-size-l;
      top: -10%;
    }

    div {
      button {
        top: -10%;
        box-shadow: none;
      }
    }
    input {
      font-size: $font-size-m;
      padding: 0;
    }
  }
}
