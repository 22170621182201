@import "../../../assets/styles/variables";

.noOddsContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;
}

.searchAndBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  & > div {
    height: 30px;
  }
  & > div > div {
    height: 100%;
  }
}
.searchIcon {
  font-size: $font-size-xl;
  display: flex;
}

.loadBtn {
  margin-left: -1px;
  height: 29px;
  background-color: $coupon-button-color;
  color: $white-text-color;
  text-transform: capitalize;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid $coupon-button-color;
  &:hover {
    background-color: $coupon-button-color;
  }
}
.noEvents {
  margin-top: 20px;
}
