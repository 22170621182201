@import "../../../assets/styles/variables";

.content {
  background-color: $secondary-background-color;
  padding-top: 3px;
  border: 1px solid #0000002d;
  border-radius: 6px;
}
.tabs {
  width: 100%;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
}
.tabButton {
  width: 100%;
  min-height: auto;
  height: 32px;
  background-color: $secondary-background-color;
  color: $text-color;
  border-bottom: 1px solid #ccc6c6;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  flex-grow: 1;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: none;
  &:hover {
    background-color: $secondary-background-color;
  }

  & > span {
    padding: 1px;
    line-height: 12px;
  }
}
.tabButtonActive {
  border: $coupon-button-color;
  width: 100%;
  height: 32px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: $secondary-background-color;
  border-bottom: 2px solid $coupon-button-color;
  color: $text-color;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: none;

  &:hover {
    background-color: $secondary-background-color;
  }

  & > span {
    padding: 1px;
    line-height: 12px;
  }
}
.couponLabel {
  color: $text-color;
  font-weight: 600;
}
