@import "../../../assets/styles/variables";

@keyframes flashing {
  0% {
    border: solid 1px red;
  }
  50% {
    border: solid 1px yellow;
  }
  100% {
    border: solid 1px green;
  }
}

.container {
  margin-bottom: 5px;
  padding: 2px;
  border: solid 1px transparent;
  animation: flashing 0.7s infinite;
}
.textdiv {
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.btncontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.text {
  color: var(--text-color);
  font-size: $font-size-l;
}

.button {
  font-size: $font-size-s;
  & > span > div {
    font-size: $font-size-xl !important;
  }
}
