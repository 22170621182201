@import "../../../assets/styles/variables";

.cashoutContainer {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: $max-width-mobile) {
    min-width: 0px;
    width: auto;
  }
}

.cashoutLabel {
  color: var(--text-color);
  font-size: $font-size-xl !important;
  display: flex;
  align-items: center;
}

.cashoutValue {
  font-size: $font-size-xxl !important;
  font-weight: bold !important;
  opacity: 0.8;
}

.actionButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
