@import "../../../assets/styles/variables";

.mainContainer {
  padding: 10px;
  @media (max-width: $max-width-mobile) {
    padding: 2px;
  }
}
.barcodeContainerNoReceipt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.watermark {
  position: relative;
  z-index: 1;
  opacity: 0.3;

  .watermarkText {
    position: absolute;
    margin-top: 45%;
    letter-spacing: 7px;
    transform: rotate(45deg);
    user-select: none;
  }
}
.receiptLogo {
  height: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;

  @media (max-width: $max-width-mobile) {
    height: 35px;
  }
}
.receiptHeader {
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  padding: 10px;

  @media (max-width: $max-width-mobile) {
    padding: 3px;
  }
}
.receiptHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eventsContainer {
  margin-top: 5px;
}

.eventsTitle {
  text-align: center;
}

.eventHeader {
  display: flex;
  justify-content: space-between;
  @media (max-width: $max-width-mobile) {
    gap: 5px;
  }
}

.eventContent {
  display: flex;
  justify-content: space-between;
}

.eventMarket {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.oddRequiredBox {
  display: flex;
  gap: 10px;

  .eventMarketTextProposed {
    font-size: $font-size-s;
    font-weight: bold;
    color: green;
    @media (max-width: $max-width-mobile) {
      font-size: $font-size-xs;
    }
  }

  .eventMarketTextRequired {
    font-size: $font-size-s;
    font-weight: bold;
    @media (max-width: $max-width-mobile) {
      font-size: $font-size-xxs;
    }
  }
}

.stateYellow {
  color: yellow;
  font-size: $font-size-xxs;
}
.stateRed {
  color: red;
  font-size: $font-size-xxs;
}

.stateGreen {
  color: green;
  font-size: $font-size-xxs;
}

.stateWhite {
  font-size: $font-size-xxs;
}

.systemContainer {
  margin-top: 5px;
  .systemHeaders {
    & > th {
      padding: 0px;
    }
    height: 20px;
    text-transform: uppercase;

    .systemHeader {
      padding: 0px;
      text-align: center;
    }
  }

  .systemCellLast {
    display: flex;
    flex-direction: column;
  }
}

.betContainer {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.betRequriedBox {
  display: flex;
  gap: 15px;

  .betTitleProposed {
    font-size: $font-size-xxl;
    font-weight: bold;
    text-transform: uppercase;
    color: green;

    @media (max-width: $max-width-mobile) {
      font-size: $font-size-l;
    }
  }

  .betTitleRequired {
    font-size: $font-size-xxl;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: $max-width-mobile) {
      font-size: $font-size-l;
    }
  }
}
.oddContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .oddMinMax {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .oddText {
    font-weight: bold;
  }
}
.bonus {
  padding: 5px;
  text-align: center;
}
.minMax {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .bonusText {
    font-weight: bold;
  }
}
.barcodeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.winContent {
  padding: 10px;
  text-align: center;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.acceptance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .acceptanceText {
    font-size: $font-size-l;
    font-weight: bold;
  }

  .acceptanceHourglass {
    font-size: $font-size-xxxl;
    animation-name: spin;
    animation-delay: 0.5;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}

.dropdownMenu {
  .menuItem {
    &:not(:last-child) {
      border-bottom: 1px solid grey;
    }

    .dropdownText {
      font-size: $font-size-l;
    }

    .telegramIcon {
      font-size: $font-size-xl;
    }

    svg {
      font-size: $font-size-xl;
    }
  }
}

.hourglass {
  animation: spin;
  animation-delay: 0.5s;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  p {
    font-size: $font-size-xxxl;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.itemContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

//////// Button Confirm Group
.confirmGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  justify-content: center;
  align-items: center;

  .buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.buttonRequired {
  font-size: $font-size-s;
}

.eventState {
  display: flex;
}
