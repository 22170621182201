@import "../../../assets/styles/variables";

.oddsEventContainer {
  height: auto;
  position: relative;
  margin-bottom: 2px;
  padding-left: 21px;
  display: flex;
  flex-flow: column;
  margin-top: 15px;
  box-shadow: 0 0 1px #00000020, 0 1px 3px #00000033;
  border-radius: 0.25rem;
}

.sportEvent,
.liveEvent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.25rem 0 0 0.25rem;
  position: absolute;
  width: 21px;
  left: 0;
  top: -1;
}
.sportEvent {
  background-color: $coupon-event-background-color;
  color: $white-text-color;
}
.liveEvent {
  color: $white-text-color;
  background-color: #f0a748;
  border-radius: 0.25rem 0px 0px 0.25rem;
  padding: 0px 4px;
}
.callerLabelLive,
.callerLabelSport {
  position: absolute;
  transform: rotate(90deg);
}

.event {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
}

.oddsItemContainer {
  background-color: $secondary-background-color;
  line-height: 2.5;
  min-height: 32px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  padding: 2px 0;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;
  gap: 4px;
  width: 60%;
  overflow: hidden;
  flex-wrap: wrap;
}
.marketLabelButton {
  border-radius: 5px;
  border: 1px solid $border-color;
  background-color: rgb(229, 229, 229);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-color;
  font-size: $font-size-s;
  font-weight: 600;
  text-align: center;
  padding: 5px;
  min-width: 40px;
}
.oddLabel {
  color: $text-color;
  font-size: $font-size-s;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.oddLabelButton {
  border-radius: 5px;
  border: 1px solid $border-color;
  background-color: rgb(229, 229, 229);
  min-width: 40px;
  max-width: max-content;
  padding: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.oddLabelContainer {
  color: $text-color;
  font-size: $font-size-s;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeBtn {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #726969;
  cursor: pointer;
  font-size: $font-size-xxl;
}
