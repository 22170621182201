@import "../../../assets/styles/variables";

.couponContainer {
  padding: 5px;
  min-height: 30%;
  overflow: hidden;
  position: relative;

  @media (max-width: $max-width-mobile) {
    height: 100%;
    margin-bottom: 10px;
  }
  @media (max-width: $max-width-small-desktop) {
    height: 100%;
    margin-bottom: 10px;
  }
}
.backdropContainer {
  display: flex;
  justify-content: center;
  position: relative;

  .backdrop {
    background-color: $sport-background-color;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 10%;
    .backdropText {
      font-weight: bold;
    }

    @media (max-width: $max-width-mobile) {
      width: 100%;
    }
  }
}
.responseViewsContainer {
  padding: 5px;
}

.emptyAndVerifyContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
