@import "../../../assets/styles/variables";

.titleFont {
  margin-left: 8px;
}
.betContainer {
  background-color: $background-color;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 30px;
  margin: 2px;
}
.betContainerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px;
}
.betFont {
  font-size: $font-size-l;
  font-weight: 500;
  margin-left: 3px;
  margin-right: 3px;
}
.cancelCouponIcon {
  @media (min-width: $max-width-tablet) {
    cursor: pointer;
  }
}
.iconBox {
  display: flex;
  width: max-content;
  @media (min-width: $max-width-tablet) {
    cursor: pointer;
  }
}
.iconBoxNoCursor {
  cursor: default;
  user-select: none;
}

.cashoutButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  cursor: pointer;
}
