@import "../../../assets/styles/variables";

.betcodesContainer {
  width: 100%;
}
.headerTable {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.marketHeaderContainer {
  width: 30%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}
.shortcutHeaderContainer {
  width: 30%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}
.selectionHeaderContainer {
  width: 30%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}
.bodyTableContainer {
  display: flex;
  justify-content: space-between;
}

.marketBodyContainer {
  width: 30%;
  text-align: center;
}
.shortcutBodyContainer {
  width: 30%;
  text-align: center;
}
.selectionBodyContainer {
  width: 30%;
  text-align: center;
}
