@import "../../../assets/styles/variables";

.betPopupTitle {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;

  .infoicon {
    font-size: 30px;
  }
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}
