@import "../../../assets/styles/variables";

.buttonGroup {
  display: flex;
  margin-top: 20px;
  height: 35px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;

  .button {
    font-size: $font-size-xs;
    background-color: $sport-background-color;

    .telegramIcon {
      font-size: $font-size-xl;
    }

    svg {
      font-size: $font-size-xl;
    }
  }

  .shareButton {
    display: none;

    @media (max-width: $max-width-tablet) {
      display: flex;
    }
  }

  @media (max-width: $max-width-mobile) {
    height: 40px;
    position: relative;
  }

  @media (max-width: $max-width-mobile) {
    .button {
      font-size: $font-size-xxs;
      svg {
        font-size: $font-size-xs;
      }
    }
  }
}
.menu {
  & > div > ul {
    background-color: $secondary-background-color;
  }
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  & > span {
    color: $text-color;
    font-size: $font-size-m;
  }
}

.imageIcon {
  color: $text-color;
  font-size: $font-size-xs;
  svg {
    font-size: $font-size-xxl;
  }
}

.linkIcon {
  color: green;
  font-size: $font-size-xxl;
  font-size: $font-size-xs;
  svg {
    font-size: $font-size-xxl;
  }
}
.dialogContent {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;
  padding-top: 10px;
  @media (max-width: $max-width-mobile) {
    min-height: 250px;
  }
}
.couponUpdateContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 25%;
  margin-bottom: 25%;
}
.buttonClose {
  margin-top: 15px;
}

.dropdownMenu :nth-child(3) {
  background-color: $secondary-background-color;
}
.dropdownText {
  color: black;
}

.itemContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}

.cashoutButtonRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButton {
  background-color: green;
  color: white;
  font-size: $font-size-s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;

  &:hover {
    background-color: green;
    color: white;
  }
}
