@import "../../../assets/styles/variables";

.drawer {
  display: none;
  & > :nth-child(3) {
    background-color: var(--dark-background-color);
  }

  @media (max-width: $max-width-tablet) {
    display: block;
  }
}

.closeCouponDrawerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: white;
}

.closeCouponDrawer {
  color: var(--text-color);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    height: 40%;
  }
}
