body {
  margin: 0;
  font-family: "Source Sans 3", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTreeItem-label {
  padding-left: 0px !important;
}

.Mui-selected {
  background-color: transparent !important;
}

p {
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

button {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
